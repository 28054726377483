h2 {
    font-family: "Playfair Display";
    color: #495057;
    font-weight: 350;
}

h3 {
    font-family: "Playfair Display";
    color: #495057;
    font-weight: 500;
}